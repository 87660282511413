import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ThemeContext } from "styled-components";
import { BigNumber } from "@ethersproject/bignumber";
// @ts-ignore
import { addresses } from "@fs/contracts";
import MainContainer from "../MainContainer";
import config from "../../config/config";
import {
  Column,
  Row,
  Spacer,
  SliderWithMarks,
  CardCaroussel,
  ConsentModal,
  InfoModal,
  TxModal,
  Button,
  ContentBox,
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  InputCurrencyBox,
  OverlayButton,
  Typo1,
  Typo2,
  Typo3,
} from "../../components";
import {
  useERC20Method,
  useFantomNative,
  useWalletProvider,
  useTierNFTFactory,
  TIER_NFT_FACTORY_CALL_METHODS,
  TIER_NFT_FACTORY_TX_METHODS,
  useTierStake,
  TIER_STAKE_CALL_METHODS,
  TIER_STAKE_TX_METHODS,
  useSendTransaction,
  useWeb3Modal,
  useModal,
  useDetectResolutionType,
} from "../../hooks";
import {
  ALL_CARDS,
  getNFTInfoById,
  getTierSelected,
  getTierByKey,
  getTierIndexByTokens,
  TIERS,
  formatHexToInt,
  toFormattedBalance,
  unitToWei,
  weiToUnit,
  displayTimeLeft,
  timeLeftInUnits,
} from "../../utils";
import { chainToNativeToken, chainToNetworkNameMap } from "../../utils/wallet";
import useCorrectChain from "../../hooks/useCorrectChain";
import { STAKING_CONSENT_REQUIREMENTS } from "../../shared";
import styled from "styled-components/macro";
const CategorySwitch: React.FC<any> = ({
  categories,
  activeCategory,
  setActiveCategory,
}) => {
  const { fontColor } = useContext(ThemeContext);
  return (
    <StyledRow>
      {categories &&
        categories.map((category: string) => {
          const isActive =
            category.toLowerCase() === activeCategory.toLowerCase();
          return (
            <OverlayButton
              key={`category-switch-${category}`}
              style={{
                background:
                  isActive &&
                  "radial-gradient(circle at 50% 52%, #9f87fb, #765cde 25%)",
                border: !isActive && "none",
                // borderBottom: isActive && `4px solid ${color.primary.fourth()}`,
                borderRadius: "32px",
                boxSizing: "border-box",
                color: isActive ? "white" : fontColor.secondary,
                fontSize: "1rem",
                textAlign: "center",
                padding: "1rem 1.5rem",
                width: "12.5rem",
              }}
              onClick={() => setActiveCategory(category.toLowerCase())}
            >
              {category === "nft"
                ? "FS Key NFT Staking"
                : category === "lfs"
                ? "$FS Liquid Staking"
                : "$FS Tokens"}
            </OverlayButton>
          );
        })}
    </StyledRow>
  );
};

const StyledRow = styled(Row)`
  background-color: rgb(226, 226, 226);
  border-radius: 32px;

  @media (max-width: ${(props) => props.theme.breakpoints.desktop}px) {
    display: flex;
    flex-direction: column;
  }
`;

const ReadyToStake: React.FC<any> = ({
  nativeToken,
  formattedNativeBalance,
  formattedFsBalance,
  tierNFTsOwned,
  chainId,
}) => {
  const history = useHistory();
  const resolutionType = useDetectResolutionType();
  return (
    <>
      <Heading3>Your checklist</Heading3>
      <Spacer size="sm" />
      <ContentBox
        style={{
          backgroundColor: "white",
          boxShadow: "0 0 10px 1px #e2e2e2",
          borderRadius: "32px",
        }}
      >
        <StyledReadyToStakeRow style={{ gap: "1rem" }}>
          <Column style={{ flex: 2 }}>
            <Heading4>First you need {nativeToken.symbol}</Heading4>
            <Spacer />
            <Typo2>
              {nativeToken.symbol} is needed to pay transaction fees (gas) on
              the {nativeToken.network} chain
            </Typo2>
            <Spacer />
            <Typo3> Current {nativeToken.symbol} balance</Typo3>
            <Spacer size="xs" />
            <Heading3>{`${formattedNativeBalance[0]}${formattedNativeBalance[1]}`}</Heading3>
            <Spacer />
            <Typo3
              style={{
                fontWeight: "bolder",
                color: "#765cde",
                letterSpacing: 1.4,
                cursor: "pointer",
              }}
              onClick={() =>
                window.open(
                  chainId === "64240"
                    ? `https://public-sonic.fantom.network/account`
                    : `https://www.coingecko.com/en/coins/${nativeToken.code}#markets`,
                  "_blank"
                )
              }
            >
              {`Get ${nativeToken.symbol} >`}
            </Typo3>
          </Column>
          <Spacer size="xs" />

          {resolutionType === "mobile" || resolutionType === "tablet" ? (
            <Row
              style={{
                height: "100%",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  flex: 1,
                  width: "14px",
                  height: "1px",
                  backgroundColor: "#e2e2e2",
                }}
              />
              <Heading4 style={{ color: "#e2e2e2", padding: "0 .5rem" }}>
                AND
              </Heading4>
              <div
                style={{
                  flex: 1,
                  width: "1px",
                  height: "1px",
                  backgroundColor: "#e2e2e2",
                }}
              />
            </Row>
          ) : (
            <Column
              style={{
                height: "100%",
                width: "14px",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  flex: 1,
                  width: "1px",
                  backgroundColor: "#e2e2e2",
                }}
              />
              <Heading4 style={{ color: "#e2e2e2", padding: ".5rem 0" }}>
                AND
              </Heading4>
              <div
                style={{
                  flex: 1,
                  width: "1px",
                  backgroundColor: "#e2e2e2",
                }}
              />
            </Column>
          )}

          <Spacer size="xs" />
          <Column style={{ flex: 3 }}>
            <Heading4>Then get FS tokens or a FS Key NFT</Heading4>
            <Spacer />
            <Typo2>
              In order to participate in the upcoming projects you need to have
              an active FSmart Club subscription. Activate one with FS tokens or
              a FS Key NFT.
            </Typo2>
            <Spacer />
            <Row>
              <Column>
                <Typo3>Current FS tokens on {nativeToken.network}</Typo3>
                <Spacer size="xs" />
                <Heading3>{`${formattedFsBalance[0]}${formattedFsBalance[1]}`}</Heading3>
                <Spacer />
                <Typo3
                  style={{
                    fontWeight: "bolder",
                    color: "#765cde",
                    letterSpacing: 1.4,
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    window.open(
                      chainId === "64240"
                        ? "https://sonic.fantomstarter.io/faucet"
                        : "https://equalizer.exchange/swap",
                      "_blank"
                    )
                  }
                >
                  {"Get FS Tokens >"}
                </Typo3>
              </Column>
              <Spacer />
              <Row style={{ width: "5rem", alignItems: "center" }}>
                <div
                  style={{
                    flex: 1,
                    height: "1px",
                    backgroundColor: "#e2e2e2",
                    transform: "rotate(-40deg)",
                    marginTop: "2.8rem",
                    marginRight: "-.5rem",
                  }}
                />
                <Heading4 style={{ color: "#e2e2e2", padding: ".5rem" }}>
                  OR
                </Heading4>
                <div
                  style={{
                    flex: 1,
                    height: "1px",
                    backgroundColor: "#e2e2e2",
                    transform: "rotate(-40deg)",
                    marginBottom: "2.8rem",
                    marginLeft: "-.5rem",
                  }}
                />
              </Row>
              <Spacer />
              <Column>
                <Typo3>Highest Tier FS Key on {nativeToken.network}</Typo3>
                <Spacer size="xs" />
                <Heading3>
                  {tierNFTsOwned.length ? Math.max(...tierNFTsOwned) : "-"}
                </Heading3>
                <Spacer />
                <Typo3
                  style={{
                    fontWeight: "bolder",
                    color: "#765cde",
                    letterSpacing: 1.4,
                    cursor: "pointer",
                  }}
                  onClick={() => history.push(`/store/${chainId}`)}
                >
                  {"Get your FS Key NFT>"}
                </Typo3>
              </Column>
            </Row>
          </Column>
        </StyledReadyToStakeRow>
      </ContentBox>
    </>
  );
};

const StakeNFT: React.FC<any> = ({
  tierNFTsOwned,
  isApproved,
  setStakeTierUpdate,
  tierNFTStaked,
  lockupLeft,
  tierTokensStaked,
  tierTokensStakedV2,
  chainId,
}) => {
  const history = useHistory();
  const { walletContext } = useWalletProvider();
  // const [loadWeb3Modal] = useWeb3Modal();
  const { promptChainChangeIfNeeded } = useCorrectChain(chainId);
  const { txTierNFTFactoryMethod } = useTierNFTFactory(chainId);
  const { txTierStakeMethod } = useTierStake(chainId);
  const [activeCard, setActiveCard] = useState(getNFTInfoById(1));
  const [activeCardId, setActiveCardId] = useState(1);
  const [onPresentModal] = useModal(
    <InfoModal
      title={"Success!"}
      message={`Congratulations! You successfully staked you Tier ${activeCard.tier} NFT. You can now start participating in sales.`}
      actionButtonText={"Go to projects page"}
      handleActionButton={() => history.push("/projects")}
      buttonStyle={{
        background: "radial-gradient(circle at 50% 52%, #9f87fb, #765cde 25%)",
      }}
    />,
    "nft-stake-success-modal"
  );

  const handleSetActiveCard = (cardId: number) => {
    const card = getNFTInfoById(cardId);
    setActiveCardId(cardId);
    setActiveCard(card);
  };

  const {
    sendTx: handleApproveForAll,
    isPending: isSetApprovalForAllPending,
    isCompleted: isSetApprovalForAllCompleted,
  } = useSendTransaction(() =>
    txTierNFTFactoryMethod(TIER_NFT_FACTORY_TX_METHODS.SET_APPROVAL_FOR_ALL, [
      addresses[walletContext.activeWallet.chainId].tierStake,
    ])
  );

  const {
    isPending: isTierStakeNFTPending,
    isCompleted: isTierStakeNFTCompleted,
    reset: resetStakeNFTTxContext,
  } = useSendTransaction(() =>
    txTierStakeMethod(TIER_STAKE_TX_METHODS.STAKE_TIER_NFT, [activeCardId])
  );

  const {
    sendTx: handleUnstakeNFT,
    isPending: isTierUnstakeNFTPending,
    isCompleted: isTierUnstakeNFTCompleted,
    reset: resetUnstakeNFTTxContext,
  } = useSendTransaction(() =>
    txTierStakeMethod(TIER_STAKE_TX_METHODS.UNSTAKE_TIER_NFT, [])
  );

  const [onPresentStakeNFTModal, onDismissStakeNFTModal] = useModal(
    <TxModal
      title="Are you sure you want to activate FSmart Club subscription?"
      message={
        "By activating FSmart Club subscription you agree to a minimum period of 30 days before you can deactivate. FS tokens or NFT used for FSmart Club subscription activation cannot be withdrawn or used for payment and will be locked within your wallet whilst subscription is active."
      }
      txCallback={() =>
        txTierStakeMethod(TIER_STAKE_TX_METHODS.STAKE_TIER_NFT, [activeCardId])
      }
      txButtonStates={[
        "Accept and activate",
        "Activating FSmart Club subscription...",
        "FSmart Club subscription activated!",
        "FSmart Club subscription failed",
      ]}
      onComplete={() => setStakeTierUpdate("stakeNFT")}
      isCompletedContent={
        <InfoModal
          title={"FSmart Club subscription activated!"}
          message={`Congratulations! You successfully activated your FSmart Club Subscription tier ${activeCardId}. You can now participate in available sales. `}
          actionButtonText={"Go to Projects page"}
          handleActionButton={() => history.push("/projects")}
          buttonStyle={{
            background:
              "radial-gradient(circle at 50% 52%, #9f87fb, #765cde 25%)",
          }}
          onDismiss={() => onDismissStakeNFTModal()}
        />
      }
    />,
    "stake-tokens-modal"
  );

  useEffect(() => {
    if (isTierStakeNFTCompleted) {
      resetStakeNFTTxContext();
      onPresentModal();
      return setStakeTierUpdate("stakeNFT");
    }
    if (isTierUnstakeNFTCompleted) {
      resetUnstakeNFTTxContext();
      return setStakeTierUpdate("unstakeNFT");
    }
    if (isSetApprovalForAllCompleted) {
      return setStakeTierUpdate("approveNFT");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isTierStakeNFTCompleted,
    isSetApprovalForAllPending,
    isTierUnstakeNFTCompleted,
  ]);

  useEffect(() => {
    if (tierNFTStaked) {
      handleSetActiveCard(tierNFTStaked);
    }
  }, [tierNFTStaked]);

  useEffect(() => {
    if (tierNFTsOwned && tierNFTsOwned.length) {
      handleSetActiveCard(tierNFTsOwned[0]);
    }
  }, [tierNFTsOwned]);

  return (
    <Column
      style={{ alignItems: "center", justifyContent: "center", width: "100%" }}
    >
      {(tierTokensStaked > BigNumber.from(0) ||
        tierTokensStakedV2 > BigNumber.from(0)) && (
        <Column style={{ alignSelf: "center", alignItems: "center" }}>
          <Typo1 style={{ textAlign: "center" }}>
            You are already have an active FSmart Club subscription. Unlock your
            FS tokens first so you can activate your subscription with a FS Key
            NFT instead.
          </Typo1>
          <Spacer size="xxl" />
        </Column>
      )}
      {tierNFTsOwned.length === 0 &&
        (tierTokensStaked.eq(BigNumber.from(0)) ||
          tierTokensStakedV2.eq(BigNumber.from(0))) &&
        !tierNFTStaked && (
          <>
            <Typo1 style={{ textAlign: "center" }}>
              No FS Key NFT available to subscribe.
            </Typo1>
            <Spacer />
            <Typo3
              style={{
                fontWeight: "bolder",
                color: "#765cde",
                letterSpacing: 1.4,
                cursor: "pointer",
              }}
              onClick={() => history.push(`/store/${chainId}`)}
            >
              {"Mint FS Key NFT >"}
            </Typo3>
            <Spacer size="xxl" />
          </>
        )}
      {(tierNFTsOwned.length > 0 || tierNFTStaked) &&
        (tierTokensStaked.eq(BigNumber.from(0)) ||
          tierTokensStakedV2.eq(BigNumber.from(0))) && (
          <>
            {tierNFTStaked && <Spacer size="xs" />}
            <Typo1 style={{ textAlign: "center" }}>
              {tierNFTStaked
                ? `You have subcription Tier ${activeCard.tier} with FS Key NFT.`
                : "Your FS Key NFT will be locked for 30 days after FSmart Club subscription is activated."}
            </Typo1>
            <Spacer />
            <StyledCarousalRow>
              <div style={{ flex: 1 }}>
                <CardCaroussel
                  cards={tierNFTStaked ? [tierNFTStaked] : tierNFTsOwned}
                  getActiveCard={handleSetActiveCard}
                />
              </div>
              <div style={{ flex: 1, minWidth: "240px" }}>
                <Spacer />
                <Column style={{ flex: 1, marginLeft: "2rem" }}>
                  <Heading3>{activeCard.name} NFT</Heading3>
                  <Typo3>Tier {activeCard.tier}</Typo3>
                  <Spacer />
                  <Heading3>
                    {" "}
                    {activeCard.tickets === 100
                      ? "Guaranteed"
                      : activeCard.tickets + "X"}
                  </Heading3>
                  <Typo3>Deal tickets</Typo3>
                  <Spacer />
                  <Heading3>
                    {activeCard.weight === 0 ? "TBD" : activeCard.weight + "X"}
                  </Heading3>
                  <Typo3>Weight</Typo3>
                  <Spacer />
                  {walletContext.activeWallet.address && tierNFTStaked && (
                    <>
                      <Button
                        style={{
                          width: "100%",
                          background:
                            lockupLeft >= 0
                              ? "transparent"
                              : "radial-gradient(circle at 50% 52%, #9f87fb, #765cde 25%)",
                          color: lockupLeft >= 0 && "#765cde",
                          border: lockupLeft >= 0 && "1px solid #765cde",
                        }}
                        variant="secondary"
                        onClick={() =>
                          promptChainChangeIfNeeded(handleUnstakeNFT)
                        }
                        disabled={
                          isTierUnstakeNFTPending ||
                          isTierUnstakeNFTCompleted ||
                          lockupLeft > 0
                        }
                      >
                        {lockupLeft > 0
                          ? "Locked"
                          : isTierStakeNFTPending
                          ? "Un"
                          : isTierStakeNFTCompleted
                          ? "Withdraw complete"
                          : "Unstake FS Key NFT"}
                      </Button>
                      <Spacer size="xxs" />
                      {lockupLeft > 0 && (
                        <Typo2 style={{ textAlign: "center" }}>
                          {displayTimeLeft(
                            timeLeftInUnits(Date.now() + lockupLeft * 1000)
                          )}{" "}
                          left
                        </Typo2>
                      )}
                    </>
                  )}
                  {walletContext.activeWallet.address &&
                    isApproved &&
                    !tierNFTStaked && (
                      <Button
                        style={{
                          width: "100%",
                          background:
                            "radial-gradient(circle at 50% 52%, #9f87fb, #765cde 25%)",
                        }}
                        variant="secondary"
                        onClick={() =>
                          promptChainChangeIfNeeded(onPresentStakeNFTModal)
                        }
                        disabled={
                          isTierStakeNFTPending || isTierStakeNFTCompleted
                        }
                      >
                        {isTierStakeNFTPending
                          ? "Staking..."
                          : isTierStakeNFTCompleted
                          ? "FS Key NFT staked successfully!"
                          : "Stake FS Key NFT"}
                      </Button>
                    )}
                  {walletContext.activeWallet.address &&
                    !isApproved &&
                    !tierNFTStaked && (
                      <Button
                        variant="secondary"
                        style={{
                          background:
                            "radial-gradient(circle at 50% 52%, #9f87fb, #765cde 25%)",
                        }}
                        disabled={
                          isSetApprovalForAllPending ||
                          isSetApprovalForAllCompleted
                        }
                        onClick={() =>
                          promptChainChangeIfNeeded(handleApproveForAll)
                        }
                      >
                        {isSetApprovalForAllPending
                          ? "Approving..."
                          : isSetApprovalForAllCompleted
                          ? "Yay! FSmart Club subscription was approved successfully"
                          : "Agree to FSmart Club subscription"}
                      </Button>
                    )}
                  {/* {!walletContext.activeWallet.address && (
                    <Button
                      variant="secondary"
                      style={{
                        background:
                          "radial-gradient(circle at 50% 52%, #9f87fb, #765cde 25%)",
                      }}
                      onClick={loadWeb3Modal}
                    >
                      Connect your wallet
                    </Button>
                  )} */}
                </Column>
              </div>
            </StyledCarousalRow>
            <Spacer size="xxl" />
          </>
        )}
    </Column>
  );
};

const StakeToken: React.FC<any> = ({
  fsBalance = 0,
  allowance = BigNumber.from(0),
  stakedAmount = BigNumber.from(0),
  tierNFTStaked,
  tierTokensStaked,
  setStakeTierUpdate,
  stakedLockupTimeLeft,
  chainId = config.defaultChainId,
  useV2 = false,
  allowanceSFS = BigNumber.from(0),
  sfsBalance = 0,
  tierTokenStakedType,
}) => {
  const history = useHistory();
  const [loadWeb3Modal] = useWeb3Modal();
  const { promptChainChangeIfNeeded } = useCorrectChain(chainId);
  const { walletContext } = useWalletProvider();
  const { approve } = useERC20Method();
  const { txTierStakeMethod } = useTierStake(chainId, useV2);
  const [fsTokensStakeAmount, setFsTokensStakeAmount] = useState("0");
  const [fsStakeTier, setFsStakeTier] = useState(
    stakedAmount > BigNumber.from(0)
      ? getTierSelected(weiToUnit(stakedAmount))?.tier
      : 0
  );
  const [consented, setConsented] = useState(false);
  const isApproved = allowance.gte(unitToWei(fsTokensStakeAmount.toString()));
  const isUnstakingApproved = allowanceSFS.gte(stakedAmount.toString());

  const handleStakeTier = (factor: number) => {
    const tierIndex = TIERS.findIndex((el: any) => el.factor === factor);

    if (
      factor < 0.5 ||
      factor < getTierSelected(weiToUnit(stakedAmount)).tier
    ) {
      return;
    }

    const alreadyStaked = stakedAmount ? weiToUnit(stakedAmount) : 0;
    setFsTokensStakeAmount(
      (TIERS[tierIndex].tokens - alreadyStaked).toString()
    );
    setFsStakeTier(factor);
  };

  const handleStakeTokens = (tokens: string) => {
    const alreadyStaked = stakedAmount ? weiToUnit(stakedAmount) : 0;
    const tierIndex = TIERS.findIndex((el: any, key) => {
      if (
        parseInt(tokens) + alreadyStaked >= el.tokens &&
        parseInt(tokens) + alreadyStaked < TIERS[key + 1]?.tokens
      ) {
        return true;
      } else if (
        parseInt(tokens) + alreadyStaked >= el.tokens &&
        key + 1 === TIERS.length
      ) {
        return true;
      } else {
        return false;
      }
    });

    setFsTokensStakeAmount(tokens);
    if (tierIndex !== -1) {
      setFsStakeTier(TIERS[tierIndex].factor);
    }
  };

  const [onPresentStakeModal, onDismissStakeModal] = useModal(
    <>
      {getTierByKey("factor", fsStakeTier) && (
        <TxModal
          title="Are you sure you want to activate FSmart Club subscription?"
          message={
            "By activating FSmart Club subscription you agree to a minimum period of 30 days before you can deactivate. FS tokens or NFT used for FSmart Club subscription activation cannot be withdrawn or used for payment and will be locked within your wallet whilst your subscription is active."
          }
          txCallback={() =>
            txTierStakeMethod(TIER_STAKE_TX_METHODS.STAKE_TIER_FS_TOKENS, [
              unitToWei(fsTokensStakeAmount.toString()),
            ])
          }
          onComplete={() => setStakeTierUpdate("stakeTOKENS")}
          onDismiss={() => onDismissStakeModal()}
          txButtonStates={[
            "Continue",
            "Activating FSmart Club subcription...",
            "FSmart Club subscription activated!",
            "FSmart Club subcription failed",
          ]}
          isCompletedContent={
            <InfoModal
              title="FSmart Club Subscription Activated!"
              message={`Congratulations! You successfully activated your FSmart Club subscription Tier ${
                getTierByKey("factor", fsStakeTier)?.factor
              }. You can now participate in available sales. `}
              actionButtonText={"Start Earning Now"}
              handleActionButton={() => history.push("/earn")}
              buttonStyle={{
                background:
                  "radial-gradient(circle at 50% 52%, #9f87fb, #765cde 25%)",
              }}
              onDismiss={() => onDismissStakeModal()}
            />
          }
        />
      )}
    </>,
    "stake-tokens-modal"
  );

  const [onPresentUpdateStakeModal, onDismissUpdateStakeModal] = useModal(
    <>
      {getTierByKey("factor", fsStakeTier) && (
        <TxModal
          title={`Upgrade your FSmart Club subcription to Tier ${fsStakeTier}`}
          message={
            "By upgrading your FSmart Club Tier you agree to a minimum period of 30 days before you can deactivate. FS tokens or NFT used for FSmart Club subscription activation cannot be withdrawn or used for payment and will be locked within your wallet whilst your subscription is active."
          }
          txCallback={() =>
            txTierStakeMethod(
              TIER_STAKE_TX_METHODS.UPDATE_STAKED_TIER_FS_TOKENS,
              [unitToWei(fsTokensStakeAmount.toString())]
            )
          }
          onComplete={() => setStakeTierUpdate("upgradeTOKENS")}
          onDismiss={() => onDismissUpdateStakeModal()}
          txButtonStates={[
            "Continue",
            "Upgrading FSmart Club Tier...",
            "Upgrade Successful!",
            "Upgrade Failed",
          ]}
          isCompletedContent={
            <InfoModal
              title="Upgrade Successful!"
              message={`Congratulations! You successfully upgraded your FSmart Club subscription to Tier ${
                getTierByKey("factor", fsStakeTier)?.factor
              }. You can now participate in available sales with your higher tier. `}
              actionButtonText={"Start Earning Now"}
              handleActionButton={() => history.push("/earn")}
              buttonStyle={{
                background:
                  "radial-gradient(circle at 50% 52%, #9f87fb, #765cde 25%)",
              }}
              onDismiss={() => onDismissUpdateStakeModal()}
            />
          }
        />
      )}
    </>,
    "upgrade-stake-tokens-modal"
  );

  const [onPresentApproveStakeModal, onDismissApproveStakeModal] = useModal(
    <>
      {getTierByKey("factor", fsStakeTier) && (
        <ConsentModal
          title="Participate in FantomStarter ecosystem"
          message={
            "To invest on FantomStarter, you must understand the basics of crypto investing. Please read and agree with the terms below."
          }
          padding="clamp(1em, 5vw, 5em)"
          onDismiss={() => onDismissApproveStakeModal()}
          styling="full"
          consent_requirements={STAKING_CONSENT_REQUIREMENTS}
          setConsented={() => {
            setConsented(true);
            promptChainChangeIfNeeded(() => {
              handleApprove();
              onDismissApproveStakeModal();
            });
          }}
        />
      )}
    </>,
    "stake-tokens-modal"
  );

  const [onPresentUnstakePenaltyModal] = useModal(
    <TxModal
      title={`Do you want to unstake your locked tokens and cancel FSmart Club subscription?`}
      message={`Your FS tokens are locked for ${displayTimeLeft(
        timeLeftInUnits(Date.now() + stakedLockupTimeLeft * 1000)
      )}. You will pay a penalty of 35% on the total number of FS tokens deposited if you cancel FSmart Club subscription early`}
      txCallback={() =>
        txTierStakeMethod(
          TIER_STAKE_TX_METHODS.UNSTAKE_LOCKED_TIER_FS_TOKENS,
          []
        )
      }
      txButtonStates={[
        "Unstake tokens & pay 35% penalty",
        "Unstaking...",
        "FSmart Club deactivated",
        "Failed",
      ]}
      onComplete={() => setStakeTierUpdate("unstakeTOKENS")}
    />,
    "unstake-tokens-penalty-modal"
  );

  const {
    sendTx: handleApprove,
    isPending: isApprovePending,
    isCompleted: isApproveCompleted,
  } = useSendTransaction(() =>
    approve(
      addresses[walletContext.activeWallet.chainId].tokens.FS,
      useV2
        ? addresses[walletContext.activeWallet.chainId].tierStakeV2
        : addresses[walletContext.activeWallet.chainId].tierStake
    )
  );

  const {
    sendTx: handleApproveSFS,
    isPending: isApprovePendingSFS,
    isCompleted: isApproveCompletedSFS,
  } = useSendTransaction(() =>
    approve(
      addresses[walletContext.activeWallet.chainId].tokens.sFS,
      addresses[walletContext.activeWallet.chainId].tierStakeV2
    )
  );

  const {
    sendTx: handleUnstakeUnlockedFsTokens,
    isPending: isUnstakeUnlockedFsTokensPending,
    isCompleted: isUnstakeUnlockedFsTokensCompleted,
    reset: resetUnstakeUnlockedTokensTxContext,
  } = useSendTransaction(() =>
    txTierStakeMethod(TIER_STAKE_TX_METHODS.UNSTAKE_TIER_FS_TOKENS, [])
  );

  const isUnstakeFsTokensPending = isUnstakeUnlockedFsTokensPending;
  const isUnstakeFsTokensCompleted = isUnstakeUnlockedFsTokensCompleted;

  useEffect(() => {
    if (isUnstakeUnlockedFsTokensCompleted) {
      resetUnstakeUnlockedTokensTxContext();
      return setStakeTierUpdate("unstakeTOKENS");
    }
    if (isApproveCompleted) {
      onDismissApproveStakeModal();
      return setStakeTierUpdate("approveTOKENS");
    }
    if (isApproveCompletedSFS) {
      return setStakeTierUpdate("approveSFSTOKENS");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isApproveCompleted,
    isUnstakeUnlockedFsTokensCompleted,
    isApproveCompletedSFS,
  ]);

  const resolutionType = useDetectResolutionType();
  let fsStakeTierSlider;
  if (resolutionType === "mobile" || resolutionType === "tablet") {
    fsStakeTierSlider = () => {
      if (fsStakeTier === 0.5) return [fsStakeTier];
      if (fsStakeTier === 0.75) return [fsStakeTier];
      if (fsStakeTier === 1) return [fsStakeTier];
      return [fsStakeTier];
    };
  } else {
    fsStakeTierSlider = () => {
      if (fsStakeTier === 0.5) return [0, fsStakeTier, 0.75];
      if (fsStakeTier === 0.75) return [0.5, fsStakeTier, 1];
      if (fsStakeTier === 1) return [0.75, fsStakeTier, fsStakeTier + 1];
      return [fsStakeTier - 1, fsStakeTier, fsStakeTier + 1];
    };
  }

  const isTierUpgrade = () => {
    if (!stakedAmount || stakedAmount.eq(BigNumber.from(0))) {
      return false;
    }
    return (
      TIERS[getTierIndexByTokens(weiToUnit(stakedAmount).toString())].factor <=
      fsStakeTier
    );
  };

  return (
    <Column style={{ width: "100%", alignItems: "center" }}>
      {tierNFTStaked && (
        <Column style={{ alignSelf: "center", alignItems: "center" }}>
          <Typo1 style={{ textAlign: "center" }}>
            You have a FSmart Club subcription active with FS Key NFT.
          </Typo1>
          <Spacer />
        </Column>
      )}
      {tierTokenStakedType !== "" && (
        <Column style={{ alignSelf: "center", alignItems: "center" }}>
          <Typo1 style={{ textAlign: "center" }}>
            {`You are already have an active FSmart Club subscription. Unlock your
            FS tokens first so you can activate your subscription with a ${
              tierTokenStakedType === "fs" ? "$FS Liquid Staking" : "$FS Tokens"
            } instead.`}
          </Typo1>
          <Spacer />
        </Column>
      )}
      {fsBalance < TIERS[0].tokens &&
        !tierNFTStaked &&
        tierTokenStakedType === "" &&
        stakedAmount.eq(BigNumber.from(0)) && (
          <Column style={{ alignSelf: "center", alignItems: "center" }}>
            <Typo1 style={{ textAlign: "center" }}>
              You need a minimum of {TIERS[0].tokens} FS tokens to enable FSmart
              Club. You can buy FS tokens on a DEX.
            </Typo1>
            <Spacer />
            <Typo3
              style={{
                fontWeight: "bolder",
                color: "#765cde",
                letterSpacing: 1.4,
                cursor: "pointer",
              }}
              onClick={() =>
                window.open("https://equalizer.exchange/swap", "_blank")
              }
            >
              {"Buy FS tokens >"}
            </Typo3>
            <Spacer />
          </Column>
        )}
      {(stakedAmount.gte(BigNumber.from(1)) ||
        (fsBalance >= TIERS[0].tokens && !tierNFTStaked)) &&
        tierTokenStakedType === "" && (
          <Column
            style={{ width: resolutionType === "mobile" ? "100%" : "80%" }}
          >
            <Typo1 style={{ textAlign: "center", alignSelf: "center" }}>
              {stakedAmount.gte(BigNumber.from(1))
                ? `You have ${weiToUnit(stakedAmount)} FS tokens staked.`
                : "Each subscription tier comes with different perks."}
            </Typo1>
            {useV2 && (
              <Typo1 style={{ textAlign: "center", alignSelf: "center" }}>
                {!stakedAmount.gte(BigNumber.from(1)) &&
                  "Lock FS, get sFS and use Earn to get predictable yield."}
              </Typo1>
            )}
            <Spacer />
            <Row
              style={{
                alignItems: "flex-end",
                justifyContent: "space-between",
              }}
            >
              <Heading4>{isTierUpgrade() ? "Add to lock:" : "Lock:"}</Heading4>
              <Typo3>Balance: {fsBalance} FS</Typo3>
            </Row>
            <Spacer size="xs" />
            <InputCurrencyBox
              value={fsTokensStakeAmount}
              setValue={handleStakeTokens}
              max={fsBalance}
            />
            <Spacer />
            <SliderWithMarks
              value={
                stakedAmount > BigNumber.from(0)
                  ? getTierIndexByTokens(
                      (
                        weiToUnit(stakedAmount) +
                        (parseInt(fsTokensStakeAmount) || 0)
                      ).toString()
                    ).toString()
                  : getTierIndexByTokens(fsTokensStakeAmount).toString()
                // ? weiToUnit(stakedAmount) > (parseInt(fsTokensStakeAmount) || 0)
                //   ? getTierIndexByTokens(weiToUnit(stakedAmount).toString())
                //   : getTierIndexByTokens(
                //       (
                //         parseInt(fsTokensStakeAmount + 1) +
                //         weiToUnit(stakedAmount)
                //       ).toString()
                //     ).toString()
                // : getTierIndexByTokens(
                //     (fsTokensStakeAmount + 1).toString()
                //   ).toString()
              }
              setValue={(value: number) =>
                value
                  ? handleStakeTier(TIERS[value].factor)
                  : handleStakeTier(TIERS[0].factor)
              }
              min={0}
              max={9}
              steps={1}
              markPoints={new Int8Array(TIERS.length - 1).map(
                (curr, index) => (curr = index + 1)
              )}
              color="#765BDE"
              railColor="#cbc6e1"
            />
            <Spacer />
            <Row
              style={{
                width: "100%",
                alignItems: "center",
                justifyContent:
                  resolutionType === "mobile" || resolutionType === "tablet"
                    ? "center"
                    : "center",
              }}
            >
              <StyledSliderGrid length={TIERS.length}>
                {TIERS.map((tier: any, key: number) => {
                  const { factor } = tier;

                  if (factor === 0)
                    return <span key={key} style={{ display: "none" }} />;

                  const isActive =
                    tier.factor === getTierByKey("factor", fsStakeTier)?.factor;

                  return (
                    <Button
                      key={key}
                      variant={isActive ? "tertiary" : "secondary"}
                      disabled={
                        weiToUnit(stakedAmount) >
                        getTierByKey("factor", tier.factor)?.tokens
                      }
                      style={{
                        background: isActive
                          ? "linear-gradient(90deg,#765cde 0,#df73ec 50%,#f8b16b 99%)"
                          : "#765BDE",
                        opacity: !isActive && 0.5,
                        fontSize: "14px",
                        padding: "0.35em 0.7em",
                        transform:
                          resolutionType === "mobile" ||
                          resolutionType === "tablet"
                            ? "translateX(0)"
                            : "translateX(50%)",
                        display:
                          (resolutionType === "mobile" ||
                            resolutionType === "tablet") &&
                          !isActive
                            ? "none"
                            : "block",
                      }}
                      onClick={() => handleStakeTier(factor)}
                    >
                      Tier {factor.toString()}
                    </Button>
                  );
                })}
              </StyledSliderGrid>
            </Row>
            <Spacer size="xl" />
            <Column style={{ width: "100%", alignItems: "center" }}>
              <Row
                style={{
                  width: "100",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "1rem",
                }}
              >
                {fsStakeTierSlider().map((tier, key) => {
                  if (tier < 0 || tier > 7) {
                    return (
                      <div
                        key={key}
                        style={{ width: "164px", marginRight: "4rem" }}
                      />
                    );
                  }
                  return (
                    <div
                      key={key}
                      style={{
                        backgroundImage: `${
                          stakedAmount.gt(BigNumber.from(0))
                            ? "linear-gradient(90deg,#765cde 0,#df73ec 50%,#f8b16b 99%)"
                            : "linear-gradient(90deg,#765cde 0,#765cde 100%)"
                        }`,
                        borderRadius: "36px",
                        opacity: tier !== fsStakeTier && 0.5,
                        padding: "1px",
                      }}
                    >
                      <ContentBox
                        style={{
                          width: tier === fsStakeTier ? "264px" : "164px",
                          height: tier === fsStakeTier ? "220px" : "184px",
                          backgroundColor: "#fff",
                          borderRadius: "36px",
                          cursor:
                            tier !== 0 &&
                            tier !== fsStakeTier &&
                            stakedAmount.eq(BigNumber.from(0)) &&
                            "pointer",
                        }}
                        onClick={() => {
                          if (
                            tier !== fsStakeTier &&
                            stakedAmount.eq(BigNumber.from(0))
                          ) {
                            handleStakeTier(
                              getTierByKey("factor", tier)?.factor
                            );
                          }
                        }}
                      >
                        <Column
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "280px",
                          }}
                        >
                          <Typo2
                            style={{
                              fontSize: tier !== fsStakeTier && "10px",
                            }}
                          >
                            Selected tier
                          </Typo2>
                          {tier === 0 ? (
                            <Heading2
                              style={{
                                textAlign: "center",
                                fontSize: tier !== fsStakeTier && "18px",
                                marginTop: ".2rem",
                              }}
                            >
                              No subscription
                            </Heading2>
                          ) : (
                            <>
                              <Heading2
                                style={{
                                  textAlign: "center",
                                  fontSize: tier !== fsStakeTier && "18px",
                                  marginTop: ".2rem",
                                }}
                              >
                                Tier {getTierByKey("factor", tier)?.factor}:{" "}
                                {getTierByKey("factor", tier)?.name}
                              </Heading2>
                              <Spacer />
                              <Typo2
                                style={{
                                  fontSize: tier !== fsStakeTier && "10px",
                                }}
                              >
                                Lottery tickets
                              </Typo2>
                              <Heading2
                                style={{
                                  fontSize: tier !== fsStakeTier && "18px",
                                }}
                              >
                                {getTierByKey("factor", tier)?.tickets === 100
                                  ? "Guaranteed"
                                  : getTierByKey("factor", tier)?.tickets + "X"}
                              </Heading2>
                              <Spacer />
                              <Typo2
                                style={{
                                  fontSize: tier !== fsStakeTier && "10px",
                                }}
                              >
                                Pool weight
                              </Typo2>
                              <Heading2
                                style={{
                                  fontSize: tier !== fsStakeTier && "18px",
                                }}
                              >
                                {getTierByKey("factor", tier)?.factor + "X"}
                              </Heading2>
                            </>
                          )}
                        </Column>
                      </ContentBox>
                    </div>
                  );
                })}
              </Row>
              <Spacer size="xl" />
              {walletContext.activeWallet.address &&
                stakedAmount > BigNumber.from(0) && (
                  <Column style={{ gap: "1rem" }}>
                    {isTierUpgrade() && (
                      <>
                        <Button
                          style={{
                            width: "350px",
                            background:
                              "radial-gradient(circle at 50% 52%, #9f87fb, #765cde 25%)",
                          }}
                          // disabled={
                          //   fsBalance < fsTokensStakeAmount ||
                          //   TIERS[
                          //     getTierIndexByTokens(
                          //       weiToUnit(stakedAmount).toString()
                          //     )
                          //   ].factor >= fsStakeTier
                          // }
                          variant="secondary"
                          onClick={() =>
                            promptChainChangeIfNeeded(onPresentUpdateStakeModal)
                          }
                        >
                          {"Upgrade Subscription Tier"}
                        </Button>
                      </>
                    )}
                    <Column>
                      {isUnstakingApproved || !useV2 ? (
                        <Button
                          style={{
                            width: "350px",
                          }}
                          disabled={
                            isUnstakeFsTokensPending ||
                            isUnstakeFsTokensCompleted ||
                            (sfsBalance < weiToUnit(stakedAmount) && useV2)
                          }
                          variant="quaternary"
                          onClick={
                            stakedLockupTimeLeft > 0
                              ? () =>
                                  promptChainChangeIfNeeded(
                                    onPresentUnstakePenaltyModal
                                  )
                              : () =>
                                  promptChainChangeIfNeeded(
                                    handleUnstakeUnlockedFsTokens
                                  )
                          }
                        >
                          {isUnstakeFsTokensPending
                            ? "Unstaking..."
                            : isUnstakeFsTokensCompleted
                            ? "FSmart Club subscription deactivated"
                            : sfsBalance < weiToUnit(stakedAmount) && useV2
                            ? "Don't have enough sFS tokens to unstake"
                            : "Unstake FS tokens"}
                        </Button>
                      ) : (
                        <Button
                          variant="secondary"
                          style={{
                            background:
                              "radial-gradient(circle at 50% 52%, #9f87fb, #765cde 25%)",
                          }}
                          disabled={
                            isApprovePendingSFS || isApproveCompletedSFS
                          }
                          onClick={() => {
                            if (!isApproveCompletedSFS) {
                              promptChainChangeIfNeeded(handleApproveSFS);
                            }
                          }}
                        >
                          {isApprovePendingSFS
                            ? "Approving..."
                            : isApproveCompletedSFS
                            ? "Yay! approved to burn sFS"
                            : "Approve sFS to Burn and Unstake"}
                        </Button>
                      )}
                      {stakedLockupTimeLeft > 0 && (
                        <>
                          <Typo2
                            style={{
                              color: "red",
                              fontWeight: "bold",
                              textAlign: "center",
                              paddingTop: ".5rem",
                            }}
                          >
                            Locked: Unlocks in{" "}
                            {displayTimeLeft(
                              timeLeftInUnits(
                                Date.now() + stakedLockupTimeLeft * 1000
                              )
                            )}
                            !
                          </Typo2>
                          <Spacer size="xs" />
                        </>
                      )}
                    </Column>
                  </Column>
                )}
              {walletContext.activeWallet.address &&
                isApproved &&
                stakedAmount.eq(BigNumber.from(0)) && (
                  // <Button
                  //   style={{
                  //     width: "350px",
                  //     background:
                  //       "radial-gradient(circle at 50% 52%, #9f87fb, #765cde 25%)",
                  //   }}
                  //   disabled={
                  //     isTierStakeTokensPending ||
                  //     isTierStakeTokensCompleted ||
                  //     fsBalance < fsTokensStakeAmount ||
                  //     fsStakeTier === 0
                  //   }
                  //   variant="secondary"
                  //   onClick={handleTierStakeTokens}
                  // >
                  //   {isTierStakeTokensPending
                  //     ? "Staking..."
                  //     : isTierStakeTokensCompleted
                  //     ? "Stake success"
                  //     : "Stake tokens"}
                  // </Button>
                  <Button
                    style={{
                      width:
                        resolutionType === "mobile" ||
                        resolutionType === "tablet"
                          ? "300px"
                          : "350px",
                      background:
                        "radial-gradient(circle at 50% 52%, #9f87fb, #765cde 25%)",
                    }}
                    disabled={
                      fsBalance < fsTokensStakeAmount || fsStakeTier === 0
                    }
                    variant="secondary"
                    onClick={() =>
                      promptChainChangeIfNeeded(onPresentStakeModal)
                    }
                  >
                    Select this tier
                  </Button>
                )}
              {walletContext.activeWallet.address &&
                !isApproved &&
                stakedAmount.eq(BigNumber.from(0)) && (
                  <Button
                    variant="secondary"
                    style={{
                      background:
                        "radial-gradient(circle at 50% 52%, #9f87fb, #765cde 25%)",
                    }}
                    disabled={isApprovePending || isApproveCompleted}
                    onClick={() => {
                      if (consented && !isApproveCompleted) {
                        promptChainChangeIfNeeded(handleApprove);
                      } else {
                        onPresentApproveStakeModal();
                      }
                    }}
                  >
                    {isApprovePending
                      ? "Approving..."
                      : isApproveCompleted
                      ? "Yay! FSmart Club subscription approved"
                      : "Approve FSmart Club subscription"}
                  </Button>
                )}
              {!walletContext.activeWallet.address && (
                <Button
                  variant="secondary"
                  style={{
                    background:
                      "radial-gradient(circle at 50% 52%, #9f87fb, #765cde 25%)",
                  }}
                  onClick={loadWeb3Modal}
                >
                  Connect wallet
                </Button>
              )}
            </Column>
          </Column>
        )}
    </Column>
  );
};

const Staking: React.FC<any> = () => {
  const { walletContext } = useWalletProvider();
  const { chainId }: any = useParams();

  const [stakeType, setStakeType] = useState(
    process.env.REACT_APP_FS_TIER_STAKE_V2 !== "off" &&
      addresses[chainId].tierStakeV2
      ? "lfs"
      : "nft"
  );
  const [nativeBalance, setNativeBalance] = useState(0);
  const [fsBalance, setFsBalance] = useState(0);
  const [sfsBalance, setSfsBalance] = useState(0);
  const [tierNFTsOwned, setTierNFTsOwned] = useState([]);
  const [isStakingTierNFT, setIsStakingTierNFT] = useState(false);
  const [nftIdStaked, setNFTIdStaked] = useState(null);
  const [stakeNFTIsApprovedForAll, setStakeNFTIsApprovedForAll] = useState(
    false
  );
  const [stakeNFTLockupTimeLeft, setStakeNFTLockupTimeLeft] = useState(0);
  const [allowance, setAllowance] = useState(BigNumber.from(0));
  const [allowanceV2, setAllowanceV2] = useState(BigNumber.from(0));
  const [allowanceSFS, setAllowanceSFS] = useState(BigNumber.from(0));
  const [fsTokensStakedAmount, setFsTokensStakedAmount] = useState(
    BigNumber.from(0)
  );
  const [fsTokensStakedAmountV2, setFsTokensStakedAmountV2] = useState(
    BigNumber.from(0)
  );
  const [isStakingTierFSTokens, setIsStakingTierFsTokens] = useState(false);
  const [isStakingTierFSTokensV2, setIsStakingTierFsTokensV2] = useState(false);
  const [
    stakedFsTokenLockupTimeLeft,
    setStakedFsTokenLockupTimeLeft,
  ] = useState(0);
  const [
    stakedFsTokenLockupTimeLeftV2,
    setStakedFsTokenLockupTimeLeftV2,
  ] = useState(0);
  const [stakeTierUpdate, setStakeTierUpdate] = useState(null);

  const nativeToken = chainToNativeToken[parseInt(chainId)];
  const { balanceOf, getAllowance } = useERC20Method();
  const { getNativeBalance } = useFantomNative();
  const { callTierNFTFactoryMethod, contractIsLoaded } = useTierNFTFactory(
    parseInt(chainId)
  );
  const { callTierStakeMethod } = useTierStake(parseInt(chainId));
  const { callTierStakeMethod: callTierStakeMethodV2 } = useTierStake(
    parseInt(chainId),
    true
  );

  const formattedFsBalance = toFormattedBalance(fsBalance);
  const formattedNativeBalance = toFormattedBalance(nativeBalance);

  useEffect(() => {
    if (walletContext.activeWallet.address && contractIsLoaded) {
      balanceOf(addresses[chainId].tokens.FS, parseInt(chainId)).then(
        (balance) => {
          setFsBalance(weiToUnit(balance));
        }
      );
      balanceOf(addresses[chainId].tokens.sFS, parseInt(chainId)).then(
        (balance) => {
          setSfsBalance(weiToUnit(balance));
        }
      );
      getNativeBalance(parseInt(chainId)).then((balance) => {
        setNativeBalance(weiToUnit(balance));
      });
      getAllowance(
        addresses[chainId].tokens.FS,
        addresses[chainId].tierStake,
        parseInt(chainId)
      ).then((allowance) => {
        setAllowance(allowance);
      });
      getAllowance(
        addresses[chainId].tokens.FS,
        addresses[chainId].tierStakeV2,
        parseInt(chainId)
      ).then((allowance) => {
        setAllowanceV2(allowance);
      });
      getAllowance(
        addresses[chainId].tokens.sFS,
        addresses[chainId].tierStakeV2,
        parseInt(chainId)
      ).then((allowance) => {
        setAllowanceSFS(allowance);
      });
      // fetch NFT FACTORY data
      callTierNFTFactoryMethod(TIER_NFT_FACTORY_CALL_METHODS.BALANCE_OF_BATCH, [
        ALL_CARDS,
        walletContext.activeWallet.address,
      ]).then((balance: any[]) => {
        if (!balance?.length) {
          return;
        }
        setTierNFTsOwned(
          ALL_CARDS.filter(
            (id: number, index: number) => formatHexToInt(balance[index]) > 0
          )
        );
      });
      callTierNFTFactoryMethod(
        TIER_NFT_FACTORY_CALL_METHODS.IS_APPROVED_FOR_ALL,
        [walletContext.activeWallet.address, addresses[chainId].tierStake]
      ).then((isApproved) => {
        setStakeNFTIsApprovedForAll(isApproved);
      });

      // fetch CURRENT NFT stake information from the contracts
      callTierStakeMethod(
        TIER_STAKE_CALL_METHODS.HAS_WALLET_CURRENTLY_STAKED_NFT,
        [walletContext.activeWallet.address]
      ).then((isStakingNFT) => {
        setIsStakingTierNFT(isStakingNFT);

        if (isStakingNFT) {
          callTierStakeMethod(
            TIER_STAKE_CALL_METHODS.GET_NFT_ID_ELIGIBLE_FOR_BENEFITS,
            [walletContext.activeWallet.address]
          ).then((cardId) => {
            setNFTIdStaked(parseInt(cardId));
          });
          callTierStakeMethod(
            TIER_STAKE_CALL_METHODS.GET_LOCKUP_TIME_LEFT_STAKED_NFT,
            [walletContext.activeWallet.address]
          ).then((timeLeft) => {
            setStakeNFTLockupTimeLeft(parseInt(timeLeft));
          });
        }

        if (!isStakingNFT) {
          setNFTIdStaked(null);
          setStakeNFTLockupTimeLeft(0);
        }
      });

      // fetch CURRENT FS_TOKEN stake information from the contracts
      if (addresses[chainId].tierStake) {
        callTierStakeMethod(
          TIER_STAKE_CALL_METHODS.HAS_WALLET_CURRENTLY_STAKED_FS_TOKENS,
          [walletContext.activeWallet.address]
        ).then((isStakingFSTokens) => {
          setIsStakingTierFsTokens(isStakingFSTokens);

          if (isStakingFSTokens) {
            callTierStakeMethod(
              TIER_STAKE_CALL_METHODS.GET_TOKEN_AMOUNT_STAKED_FOR_BENEFITS,
              [walletContext.activeWallet.address]
            ).then((amount) => {
              setFsTokensStakedAmount(BigNumber.from(amount));
            });
            callTierStakeMethod(
              TIER_STAKE_CALL_METHODS.GET_LOCKUP_TIME_LEFT_STAKED_FS_TOKENS,
              [walletContext.activeWallet.address]
            ).then((timeLeft) => {
              setStakedFsTokenLockupTimeLeft(parseInt(timeLeft));
            });
          }
          if (!isStakingFSTokens) {
            setFsTokensStakedAmount(BigNumber.from(0));
            setStakedFsTokenLockupTimeLeft(0);
          }
        });
      }

      // fetch CURRENT FS_TOKEN stake information from the V2 contracts
      if (
        process.env.REACT_APP_FS_TIER_STAKE_V2 !== "off" &&
        addresses[chainId].tierStakeV2
      ) {
        callTierStakeMethodV2(
          TIER_STAKE_CALL_METHODS.HAS_WALLET_CURRENTLY_STAKED_FS_TOKENS,
          [walletContext.activeWallet.address]
        ).then((isStakingFSTokens) => {
          setIsStakingTierFsTokensV2(isStakingFSTokens);

          if (isStakingFSTokens) {
            callTierStakeMethodV2(
              TIER_STAKE_CALL_METHODS.GET_TOKEN_AMOUNT_STAKED_FOR_BENEFITS,
              [walletContext.activeWallet.address]
            ).then((amount) => {
              setFsTokensStakedAmountV2(BigNumber.from(amount));
            });
            callTierStakeMethodV2(
              TIER_STAKE_CALL_METHODS.GET_LOCKUP_TIME_LEFT_STAKED_FS_TOKENS,
              [walletContext.activeWallet.address]
            ).then((timeLeft) => {
              setStakedFsTokenLockupTimeLeftV2(parseInt(timeLeft));
            });
          }
          if (!isStakingFSTokens) {
            setFsTokensStakedAmountV2(BigNumber.from(0));
            setStakedFsTokenLockupTimeLeftV2(0);
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletContext.activeWallet.address, stakeTierUpdate, contractIsLoaded]);

  return (
    <MainContainer>
      <Spacer size="xxl" />
      <Heading1>Manage your subscription</Heading1>
      <Heading3>{chainToNetworkNameMap[chainId]}</Heading3>
      <Spacer size="sm" />
      <Typo2>
        Get access to FS deals with FSmart Club subscription. Activate it with
        FS tokens or FS Key NFT.
      </Typo2>
      <Spacer size="xl" />
      <Column>
        <>
          {!isStakingTierFSTokens &&
            !isStakingTierFSTokensV2 &&
            !isStakingTierNFT && (
              <>
                <ReadyToStake
                  nativeToken={nativeToken}
                  formattedNativeBalance={formattedNativeBalance}
                  formattedFsBalance={formattedFsBalance}
                  tierNFTsOwned={tierNFTsOwned}
                  chainId={chainId}
                />
                <Spacer />
              </>
            )}
          <ContentBox
            style={{
              backgroundColor: "white",
              borderRadius: "32px",
              boxShadow:
                isStakingTierFSTokens ||
                isStakingTierNFT ||
                isStakingTierFSTokensV2
                  ? "0 0 14.2px 0 #765cde"
                  : "0 0 10px 1px #e2e2e2",
            }}
          >
            <Column style={{ width: "100%", alignItems: "center" }}>
              <StyledHeading1>
                {isStakingTierFSTokens ||
                isStakingTierNFT ||
                isStakingTierFSTokensV2
                  ? "You have a subscription"
                  : "Activate your subscription"}
              </StyledHeading1>
              <Spacer />
              {process.env.REACT_APP_FS_TIER_STAKE_V2 !== "off" &&
              addresses[chainId].tierStakeV2 ? (
                <CategorySwitch
                  categories={
                    addresses[chainId].tierStake
                      ? ["nft", "lfs", "fs"]
                      : ["nft", "lfs"]
                  }
                  setActiveCategory={setStakeType}
                  activeCategory={stakeType}
                />
              ) : (
                <CategorySwitch
                  categories={["nft", "fs"]}
                  setActiveCategory={setStakeType}
                  activeCategory={stakeType}
                />
              )}
              <Spacer />
              {stakeType === "nft" && (
                <StakeNFT
                  tierNFTsOwned={tierNFTsOwned}
                  isApproved={stakeNFTIsApprovedForAll}
                  setStakeTierUpdate={setStakeTierUpdate}
                  tierNFTStaked={nftIdStaked}
                  tierTokensStaked={fsTokensStakedAmount}
                  tierTokensStakedV2={fsTokensStakedAmountV2}
                  lockupLeft={stakeNFTLockupTimeLeft}
                  chainId={parseInt(chainId)}
                />
              )}
              {stakeType === "fs" && addresses[chainId].tierStake && (
                <StakeToken
                  fsBalance={fsBalance}
                  allowance={allowance}
                  setStakeTierUpdate={setStakeTierUpdate}
                  stakedAmount={fsTokensStakedAmount}
                  tierNFTStaked={nftIdStaked}
                  tierTokensStaked={fsTokensStakedAmount}
                  stakedLockupTimeLeft={stakedFsTokenLockupTimeLeft}
                  chainId={parseInt(chainId)}
                  tierTokenStakedType={isStakingTierFSTokensV2 ? "lfs" : ""}
                />
              )}
              {process.env.REACT_APP_FS_TIER_STAKE_V2 !== "off" &&
                addresses[chainId].tierStakeV2 &&
                stakeType === "lfs" && (
                  <StakeToken
                    fsBalance={fsBalance}
                    allowance={allowanceV2}
                    setStakeTierUpdate={setStakeTierUpdate}
                    stakedAmount={fsTokensStakedAmountV2}
                    tierNFTStaked={nftIdStaked}
                    tierTokensStaked={fsTokensStakedAmountV2}
                    stakedLockupTimeLeft={stakedFsTokenLockupTimeLeftV2}
                    chainId={parseInt(chainId)}
                    useV2={true}
                    allowanceSFS={allowanceSFS}
                    sfsBalance={sfsBalance}
                    tierTokenStakedType={isStakingTierFSTokens ? "fs" : ""}
                  />
                )}
            </Column>
          </ContentBox>
        </>
      </Column>
    </MainContainer>
  );
};

export default Staking;

const StyledReadyToStakeRow = styled(Row)`
  gap: 1rem;
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}px) {
    flex-direction: column;
  }
`;

const StyledSliderGrid = styled.div<{ length: number }>`
  display: grid;
  grid-template-columns: ${(props) => `repeat( ${props.length - 1}, 1fr)`};
  justify-items: end;
  width: calc(100% - 0.5em);
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}px) {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: auto;
  }
`;

const StyledCarousalRow = styled(Row)`
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const StyledHeading1 = styled(Heading1)`
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}px) {
    text-align: center;
  }
`;
