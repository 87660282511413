import React, { useEffect, useState } from "react";
import Row from "../Row";
import Spacer from "../Spacer";
import InputCurrency from "./InputCurrency";
import { FS_TOKEN } from "../../utils";
import { Heading4, Typo2 } from "../index";
import { useDetectResolutionType } from "../../hooks";

const InputCurrencyBox: React.FC<any> = ({
  value,
  max,
  setValue,
  disabled,
  noError,
  small,
}) => {
  const resolutionType = useDetectResolutionType();

  const [error, setError] = useState(null);
  const handleSetMax = () => {
    setError(null);
    setValue(max);
  };

  useEffect(() => {
    setError(null);
    if (noError) {
      return;
    }
    if (parseFloat(value) > parseFloat(max)) {
      setError("Not enough tokens");
    }
  }, [value, max]);

  useEffect(() => {
    if (noError) {
      setError(null);
    }
  }, [noError]);

  return (
    <Row
      style={{
        width: "100%",
        // backgroundColor: "#202F49",
        borderRadius: "8px",
        height: "46px",
        alignItems: "center",
        border: "1px solid lightgrey",
      }}
    >
      <Spacer
        size={
          resolutionType === "mobile" || resolutionType === "tablet"
            ? "xxs"
            : "md"
        }
      />
      <InputCurrency
        disabled={disabled}
        value={value}
        max={max}
        handleValue={setValue}
        handleError={setError}
        token={FS_TOKEN}
        small={small}
      />
      <Row
        style={{
          alignItems: "center",
          justifyContent: noError ? "end" : "space-between",
          gap: "10px",
        }}
      >
        {!noError && (
          <Typo2
            style={{
              color: "red",
              fontSize:
                resolutionType === "mobile" || resolutionType === "tablet"
                  ? "14px"
                  : "16px",
            }}
          >
            {error}
          </Typo2>
        )}
        <Heading4
          style={{
            alignSelf: "flex-end",
            color: "#765cde",
            letterSpacing: 1.4,
            cursor: disabled ? "not-allowed" : "pointer",
            marginRight: "14px",
            opacity: disabled && 0.5,
            fontSize:
              resolutionType === "mobile" || resolutionType === "tablet"
                ? "0.725rem"
                : "1.125rem",
            whiteSpace: "nowrap",
          }}
          onClick={() => {
            if (parseFloat(value) > parseFloat(max)) {
              window.open("https://equalizer.exchange/swap", "_blank");
            } else {
              if (!disabled) handleSetMax();
            }
          }}
        >
          {parseFloat(value) > parseFloat(max) ? "BUY" : "MAX"}
        </Heading4>
      </Row>
    </Row>
  );
};

export default InputCurrencyBox;
